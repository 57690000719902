import { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import ManageUsers from '../../logic/ManageUsers';
import useUserLocalId from '../hooks/useUserLocalId';

const ProfileView = () => {
    const [formData, setFormData] = useState({
      firstName: '',
      lastName: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zipCode: '',
      associatedUserId: '',
    });
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formFilled, setFormFilled] =useState(false);
    const { userId } = useUserLocalId();
    const manageUsers = new ManageUsers();
  
    useEffect(() => {
      const fetchNurseDataAndUpdateForm = async () => {
        if (!userId) {
          return;
        }
  
        const nurseData = await manageUsers.getNurseIdFromUserId(userId.id);
        console.log('nurseData from fetchNurseDataAndUpdateForm', nurseData);
        if (nurseData !== null)
        {
            setFormData({ ...nurseData });  
            setFormFilled(true);
        }
      };
      if (!formFilled) { fetchNurseDataAndUpdateForm(); };
    }, [userId, manageUsers, formFilled]);
  
    const mutation = useMutation(async (nurseData) => {
      const response = await fetch('api/nurses', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(nurseData),
      });
  
      if (!response.ok) {
        throw new Error('Error posting nurse data');
      }
  
      return response.json();
    });
  
    const handleSubmit = (e) => {
      e.preventDefault();
      mutation.mutate(formData);
      setFormSubmitted(true);
    };
  
    if (formSubmitted && !mutation.isLoading && !mutation.isError) {
      return <div>Form submitted successfully!</div>;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value !== null ? value : '',
        }));
      };


    return (
        <div>
            <form onSubmit={handleSubmit}>
                <label htmlFor="firstName">Nurse First Name</label>
                <input type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                    value={formData.firstName}
                    onChange={handleChange}></input><br></br>
                <label htmlFor="lastName">Nurse Last Name</label>
                <input type="text"
                    id="lastName"
                    name="lastName"
                    placeholder="Last Name"
                    value={formData.lastName}
                    onChange={handleChange}></input><br></br>
                <label htmlFor="addressLine1">Address Line 1</label>
                <input type="text"
                    id="addressLine1"
                    name="addressLine1"
                    placeholder="Address Line 1"
                    value={formData.addressLine1}
                    onChange={handleChange}></input><br></br>
                <label htmlFor="addressLine2">Address Line 1</label>
                <input type="text"
                    id="addressLine2"
                    name="addressLine2"
                    placeholder="Address Line 2"
                    value={formData.addressLine2}
                    onChange={handleChange}></input><br></br>
                <label htmlFor="city">City</label>
                <input type="text"
                    id="city"
                    name="city"
                    placeholder="City"
                    value={formData.city}
                    onChange={handleChange}></input><br></br>
                <label htmlFor="state">State</label>
                <input type="text"
                    id="state"
                    name="state"
                    placeholder="State"
                    value={formData.state}
                    onChange={handleChange}></input><br></br>
                <label htmlFor="zipCode">Zip Code</label>
                <input type="text"
                    id="zipCode"
                    name="zipCode"
                    placeholder="Zip Code"
                    value={formData.zipCode}
                    onChange={handleChange}></input><br></br>
                <button type="submit">Submit Nurse</button>
            </form>
        </div>
    );
}

export default ProfileView;