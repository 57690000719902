import React, { Component } from 'react';

export class Features extends Component {
  render() {
    return (
      <section id="features">
        <div className="container">
          <h1 className="text-center">Exciting Features</h1>
          <div className="row">
            <div className="col-lg-4 mt-4">
              <div className="card servicesText">
                <div className="card-body">
                  <i className="fas servicesIcon fa-desktop"></i>
                  <h4 className="card-title mt-3">Empower Nurses and Caregivers</h4>
                  <p className="card-text mt-3">
                    Take control of your schedule. Sign up and pick appointments that fit your lifestyle.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-4">
              <div className="card servicesText">
                <div className="card-body">
                  <i className='fas servicesIcon fa-layer-group'></i>
                  <h4 className="card-title mt-3">Streamline Provider Shift Management</h4>
                  <p className="card-text mt-3">
                    Fill medical shifts with ease. Set your requirements and let skilled nurses cover your shifts.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-4">
              <div className="card servicesText">
                <div className="card-body">
                  <i className='fas servicesIcon fa-fire'></i>
                  <h4 className="card-title mt-3">Hassle-Free Payments</h4>
                  <p className="card-text mt-3">
                    Automate payments on a schedule or pay instantly after a shift is completed and confirmed.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 mt-4">
              <div className="card servicesText">
                <div className="card-body">
                  <i className='fas servicesIcon fa-search'></i>
                  <h4 className="card-title mt-3">Paperless Scheduling</h4>
                  <p className="card-text mt-3">
                    View all your upcoming appointments in one user-friendly interface.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-4">
              <div className="card servicesText">
                <div className="card-body">
                  <i className='fas servicesIcon fa-cog'></i>
                  <h4 className="card-title mt-3">Stay Informed with Notifications</h4>
                  <p className="card-text mt-3">
                    Receive reminders for upcoming appointments and sync them with your calendar.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-4">
              <div className="card servicesText">
                <div className="card-body">
                  <i className='fas servicesIcon fa-check-square'></i>
                  <h4 className="card-title mt-3">Built with Quality in Mind</h4>
                  <p className="card-text mt-3">
                    Our platform is developed using test-driven techniques ensuring a high-quality user experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
