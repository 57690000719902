import React from 'react';
import { useQuery } from 'react-query';

function PatientsList() {
  const { isLoading, error, data } = useQuery('getPatientData', () =>
    fetch('api/patients').then((res) => res.json())
  );
  console.log(data);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="container">
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">First Name</th>
            <th scope="col">Last Name</th>
            <th scope="col">Address Line 1</th>
            <th scope="col">Address Line 2</th>
            <th scope="col">City</th>
            <th scope="col">State</th>
            <th scope="col">Zip Code</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              <td>{item.firstName}</td>
              <td>{item.lastName}</td>
              <td>{item.addressLine1}</td>
              <td>{item.addressLine2 ?? ""}</td>
              <td>{item.city}</td>
              <td>{item.state}</td>
              <td>{item.zipCode}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default PatientsList;