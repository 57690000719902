class ManageUsers {
  getUserIdFromAuthId(authProviderId) {
    console.log("Tryingto find the auth providerid")
    return new Promise((resolve, reject) => {
      fetch(`api/users/getUserFromAuthId/${authProviderId}`)
        .then((res) => res.json())
        .then((data) => {
          console.log("userfound:", data);
          resolve(data.id);
        })
        .catch((error) => {
          console.error('Error:', error);
          reject(error);
        });
    });
  }

  async getNurseIdFromUserId(userId) {
    const response = await fetch(`/api/nurses/getNurseIdFromUserId/${userId}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.id; 
  }
}

export default ManageUsers;