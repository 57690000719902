import React, { Component } from 'react';


export class About extends Component {
  render() {
    return (
      <section id="about">
        <div className="container mt-4 pt-4">
          <h1 className="text-center">About FlexNursePortal</h1>
          <div className="row mt-4">
            <div className="col-lg-12">
              <p> You work hard to help others. Let us help you manage your time. With FlexNursePortal you can look at shifts near you and pick your own schedule.
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
