import { useState, React } from 'react';
import { useQuery, useMutation } from 'react-query';
import './FormStyles.css';


const FacilitiesEntry = () => {

  const postFacility = async (facilitiesData) => {
    const response = await fetch('api/facilities', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(facilitiesData),
    });

    if (!response.ok) {
      throw new Error('Error posting facilities data');
    }

    return response.json();
  };

  const mutation = useMutation(postFacility);

  const [formData, setFormData] = useState({
    locationName: '',
    department: '',
    room: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    mutation.mutate(formData);
  };

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        {["locationName", "department", "room", "addressLine1", "addressLine2", "city", "state", "zipCode"].map((field, idx) => (
          <div className="row" key={idx}>
            <div className="col-md-6 mb-3">
              <label htmlFor={field}>{field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')}</label>
              <input
                type="text"
                className="form-control"
                id={field}
                name={field}
                placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
                value={formData[field]}
                onChange={(e) => setFormData({ ...formData, [field]: e.target.value })}
              />
            </div>
          </div>
        ))}
        <button type="submit" className="submit-button">Submit Facility</button>
      </form>
    </div>
  );
}

export default FacilitiesEntry;