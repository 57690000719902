import React, { useState } from 'react';
import { getAuth, signInWithPopup, signInWithEmailAndPassword, GoogleAuthProvider } from "firebase/auth";
import { googleProvider } from "../firebase"; // Import googleProvider from your firebase.js file
import { Navigate } from "react-router-dom";

import { useQuery, useMutation } from 'react-query';

const submitNewUser = async (userData) => {
  console.log(userData);
  const response = await fetch('api/users', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userData),
  });

  if (!response.ok) {
    throw new Error('Error posting user data');
  }

  return response.json();
};


const userExists = async (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const userData = await fetch(`api/users/GetUserByUserName/${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!userData.ok) {
        if (userData.status === 404) {
          resolve(false);
        } else {
          throw new Error(`Error getting user: ${userData.status}`);
        }
      } else {
        const user = await userData.json();
        //console.log("user data: ", user);

        if (user) {
          // User exists
          resolve(true);
        } else {
          // User doesn't exist
          resolve(false);
        }
      }
    } catch (error) {
      console.error("Error in userExists:", error);
      reject(error);
    }
  });
};

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const newUserMutation = useMutation(submitNewUser);

  const handleSignUpEmailPassword = async (e) => {
    const auth = getAuth();
    e.preventDefault();
    try {
      await auth.createUserWithEmailAndPassword(email, password);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleSignUpWithGoogle = async () => {
    console.log("entering HandleSignupWithGoogle");
    try {
      const auth = getAuth();
      const result = await signInWithPopup(auth, googleProvider);
      console.log("getting HandleSignupWithGoogle results");
      console.log("result object:", result); // Log the result object here

      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      const user = result.user;

      const doesUserExist = await userExists(user.email);
      //console.log("if user exists", doesUserExist);

      if (!doesUserExist) {
        // console.log("user doesn't exist");
        // console.log(user.uid);
        
        newUserMutation.mutate({ userName: user.email, authProviderId: user.uid });
      }
      if (result.user !== null) {
        return <navigate replace to='/'></navigate>
      }

    } catch (error) {
      console.error("Error in handleSignUpWithGoogle:", error);
    }
  };

  return (
    <div>
      {/* <h2>Sign Up with Email and Password</h2>
            <form onSubmit={handleSignUpEmailPassword}>
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button type="submit">Sign Up</button>
            </form> */}
      <h2>Sign Up with Google</h2>
      <button onClick={handleSignUpWithGoogle}>Sign Up with Google</button>
    </div>
  );
};

export default SignUp;
