import React, { Component, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../AuthContext'
import SignIn from './SignIn';
import  NavMenu  from './NavMenu';
import { NavMenuIfNotAuth } from './NavMenuIfNotAuth';

const Home = () => {
  const { currentUser } = useContext(AuthContext);
  const LoggedInView = () => (
    <div>
      {currentUser ? <NavMenu /> : <NavMenuIfNotAuth />}
      <h2>Administration</h2>
      <Link to="/patients-admin">Patients</Link><br></br>
      <Link to="/nurses-admin">Nurses</Link><br></br>
      <Link to="/facilities-admin">Facilities</Link><br></br>
      <Link to="/appointments-admin">Appointments</Link><br></br>
    </div>
  );

  return (currentUser ? <LoggedInView></LoggedInView> : <><NavMenuIfNotAuth /><SignIn></SignIn></>)
}

export default Home