import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from './Sidebar';
import MainContent from './MainContent';
import React, { useEffect, useContext, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';

import { Outlet } from 'react-router-dom';

const Dashboard = ({ children }) => {
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();
    const { currentUser } = useContext(AuthContext);

    const isLoggedIn = () => {
        console.log("current user", currentUser);
        return currentUser !== null;
    };

    const userExists = async (userId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const userData = await fetch(`api/users/GetUserByUserName/${userId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!userData.ok) {
                    if (userData.status === 404) {
                        resolve(false);
                    } else {
                        throw new Error(`Error getting user: ${userData.status}`);
                    }
                } else {
                    const user = await userData.json();
                    //console.log("user data: ", user);

                    if (user) {
                        // User exists
                        resolve(true);
                    } else {
                        // User doesn't exist
                        resolve(false);
                    }
                }
            } catch (error) {
                console.error("Error in userExists:", error);
                reject(error);
            }
        });
    };

    useEffect(() => {
        const checkUserAndRedirect = async () => {
            const exists = await userExists(currentUser.email);
            if (!exists) {
                navigate('/signin', { replace: true });
            }
            setLoading(false);
        };

        if (currentUser) {
            checkUserAndRedirect();
        } else {
            setLoading(false);
            navigate('/signin', { replace: true });
        }
    }, [currentUser, navigate]);

    return (
        <div className="container-fluid">
            {loading ? (
                <div>Loading...</div>
            ) : (
                <div className="row flex-nowrap">
                    <Sidebar />
                    <MainContent>
                        <Outlet />
                        {children}
                    </MainContent>
                </div>
            )}
        </div>
    );
};

export default Dashboard;
