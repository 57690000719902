import { QueryClient, QueryClientProvider } from 'react-query';
import { Routes, Route, useLocation, useRoutes } from 'react-router-dom';
import Layout from './components/Layout';
import AppRoutes from './AppRoutes';


function App() {
  const location = useLocation();
  const isDashboardRoute = location.pathname !== '/';
  const queryClient = new QueryClient();
  const routing = useRoutes(AppRoutes);
  return (
    <QueryClientProvider client={queryClient}>
      <Layout>{routing}</Layout>
    </QueryClientProvider>
  );
}

export default App;