import React, { Component } from 'react';

import NursesEntry from './management/NursesEntry';
import NursesList from './management/NursesList';

export class NursesAdmin extends Component {
  static displayName = NursesAdmin.name;

  render() {
    return (
      <div>
        <h2>Add Nurses</h2>
        <NursesEntry></NursesEntry>
        <h2>List of Nurses</h2>
        <NursesList></NursesList>
      </div>
    );
  }
}
