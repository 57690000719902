import { useMutation } from 'react-query';
import ManageUsers from '../../logic/ManageUsers';

const useAssignAppointment = (userId) => {

  const manageUsers = new ManageUsers();

  const assignAppointmentToUser = async (appointmentId) => {
    const manageUsers = new ManageUsers();
    const nurse = await manageUsers.getNurseIdFromUserId(userId);
    console.log("found or created nurse:", nurse);
    const response = await fetch(`api/appointments/assignnurse`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        appointmentId: appointmentId,
        nurseId: nurse
      }),
    });
  
    if (!response.ok) {
      throw new Error('Error assigning appointment to user');
    }
  
    return response.json();
  };

  const mutation = useMutation(assignAppointmentToUser);

  return mutation;
};

export default useAssignAppointment;

const getNurseId = async (userId) => {
  const manageUsers = new ManageUsers();
  return new Promise((resolve, reject) => {
    const intervalId = setInterval(() => {
      const nurseId = manageUsers.getNurseIdFromUserId(userId);
      if (nurseId) {
        clearInterval(intervalId);
        resolve(nurseId);
      }
    }, 500);
  });
}