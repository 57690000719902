import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../AuthContext';
import useAppointmentsForNurse from '../hooks/useAppointmentsForNurse';
import ManageUsers from '../../logic/ManageUsers';

function MyAppointments() {
  const { currentUser } = useContext(AuthContext);
  const [nurseId, setNurseId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (currentUser) {
        const users = new ManageUsers();
        const userId = await users.getUserIdFromAuthId(currentUser.uid);
        const nurseId = await users.getNurseIdFromUserId(userId);
        if (nurseId) {
          console.log(nurseId);
          setNurseId(nurseId);
        }
      }
    };
    fetchData();
  }, [currentUser]);

  const { isLoading, error, data, refetch } = useAppointmentsForNurse(nurseId);

  if (currentUser === null) {
    return <div>Please sign in to view your appointments.</div>;
  }

  if (!nurseId) {
    return <div>Loading...</div>;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      {data == undefined || data.length === 0 ? (
        <p>
          You currently have no appointments scheduled. Browse and select open
          appointments. They will appear here.
        </p>
      ) : (
        <div className="container">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Location</th>
                <th scope="col">Department</th>
                <th scope="col">Room</th>
                <th scope="col">Start Time</th>
                <th scope="col">End Time</th>
                <th scope="col">Assigned To</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.id}>
                  <td>{item.locationName}</td>
                  <td>{item.department ?? ""}</td>
                  <td>{item.room ?? ""}</td>
                  <td>{item.startTime}</td>
                  <td>{item.endTime}</td>
                  <td>
                    Assigned to: <strong>{currentUser.displayName}</strong>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}

export default MyAppointments;
