import React, { Component } from 'react';
import { About } from './About';
import { Features } from './Features';
import Contact from './Contact';
import Hero from './Hero';
import Showcase from './Showcase';
import Footer from './Footer';
import NavMenu from './NavMenu';
import './Landing.css';

export class Landing extends Component {
  static displayName = Landing.name;
  
  render() {
    return (
      <>
      <NavMenu />
      <Hero />
      <About/>
      <Features/>
      {/* <Showcase/> */}
      <Contact/>
      <Footer/>
    </>
    );
  }
}
