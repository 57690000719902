import React from 'react';
import { useQuery } from 'react-query';

function FacilitiesList() {
  const { isLoading, error, data } = useQuery('getFacilitiesData', () =>
    fetch('api/facilities').then((res) => res.json())
  );
  console.log(data);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <table className="table table-hover table-striped table-responsive">
            <thead>
              <tr>
                <th scope="col">Location Name</th>
                <th scope="col">Department</th>
                <th scope="col">Room</th>
                <th scope="col">Address Line 1</th>
                <th scope="col">Address Line 2</th>
                <th scope="col">City</th>
                <th scope="col">State</th>
                <th scope="col">Zip Code</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.id}>
                  <td>{item.locationName}</td>
                  <td>{item.department}</td>
                  <td>{item.room}</td>
                  <td>{item.addressLine1}</td>
                  <td>{item.addressLine2}</td>
                  <td>{item.city}</td>
                  <td>{item.state}</td>
                  <td>{item.zipCode}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default FacilitiesList;