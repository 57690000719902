import { useQuery } from 'react-query';

const useAppointmentsData = () => {
  const { isLoading, error, data, refetch } = useQuery('getAppointmentsList', () =>
    fetch('api/appointments/getwithlocations').then((res) => res.json())
  );

  return { isLoading, error, data, refetch };
};

export default useAppointmentsData;
