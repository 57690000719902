import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import ManageUsers from '../../logic/ManageUsers';
import useAppointmentsData from '../hooks/useAppointmentsData';
import useUserLocalId from '../hooks/useUserLocalId';

const AppointmentsEntry = ({ onAppointmentAdded }) => {
  const { isUserLoading, isUserError, userId } = useUserLocalId();

  const postAppointment = async (appointmentsData) => {
    const response = await fetch('api/appointments', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(appointmentsData),
    });

    if (!response.ok) {
      console.log(response.status, await response.json())
      throw new Error('Error posting appointments data');
    }

    return response.json();
  };
  const [formData, setFormData] = useState({
    facilitiesId: '',
    startTime: '',
    endTime: '',
    // assignedBy: ''
  });

  const mutation = useMutation(postAppointment, {
    onSuccess: () => {
      if (onAppointmentAdded) {
        onAppointmentAdded();
      }
    },
  });

  useEffect(() => {
    if (!userId || !userId.id) {
      return;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      assignedBy: userId.id,
    }));
  }, [userId]);

  const { isLoading, error, data } = useQuery('getFacilitiesDataForDropDown', () =>
    fetch('api/facilities').then((res) => res.json())
  );

  useEffect(() => {
    if (data && data.length > 0) {
      setFormData((prevState) => ({ ...prevState, facilitiesId: data[0].id }));
    }
  }, [data]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (isUserLoading) {
    return <div>Loading...</div>;
  }

  if (isUserError) {
    return <div>Error: {isUserError.message}</div>;
  }







  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("formData:", formData);
    mutation.mutate(formData);
  };



  console.log(data);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading facilites for drop down: {error.message}</div>;
  }



  return (
    <div className="container">
      <form onSubmit={handleSubmit} className="mt-3">
        <div className="form-group">
          <label htmlFor="facilitiesId">Select Facility</label>
          <select
            id="facilitiesId"
            name="facilitiesId"
            className="form-control"
            onChange={(e) =>
              setFormData({ ...formData, facilitiesId: e.target.value })
            }
          >
            {data.map((facility) => (
              <option key={facility.id} value={facility.id}>
                {`${facility.locationName} - ${
                  facility.department ?? ""
                } - ${facility.room ?? ""}`}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="startTime">Start Time</label>
          <input
            type="datetime-local"
            id="startTime"
            name="startTime"
            className="form-control"
            placeholder="Start Time"
            value={formData.startTime}
            onChange={(e) =>
              setFormData({ ...formData, startTime: e.target.value })
            }
          />
        </div>
        <div className="form-group">
          <label htmlFor="endTime">End Time</label>
          <input
            type="datetime-local"
            id="endTime"
            name="endTime"
            className="form-control"
            placeholder="End Time"
            value={formData.endTime}
            onChange={(e) =>
              setFormData({ ...formData, endTime: e.target.value })
            }
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Create Appointment
        </button>
      </form>
    </div>
  );
}

export default AppointmentsEntry;