import React, { Component, useContext } from 'react';
import { Container } from 'reactstrap';

const Layout = ({ children }) => {
  return (
    <div>
      {/* <Container tag="main">{children}</Container> */}
      {children}
    </div>
  );
};

export default Layout;
