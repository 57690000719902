import { useQuery } from 'react-query';

const fetchAppointmentsForNurse = async (nurseId) => {
  if (!nurseId) {
    return [];
  }
  const response = await fetch(`/api/appointments/getwithlocationsfornurse/${nurseId}`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

const useAppointmentsForNurse = (nurseId) => {
  return useQuery(['appointments', nurseId], () => fetchAppointmentsForNurse(nurseId));
};

export default useAppointmentsForNurse;
