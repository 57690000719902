import React, { useState, useEffect, useContext } from 'react';
import { getAuth, signInWithPopup, signInWithEmailAndPassword, GoogleAuthProvider } from "firebase/auth";
import { googleProvider } from "../firebase"; // Import googleProvider from your firebase.js file
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';

import { AuthContext } from '../AuthContext';
import RegistrationForm from './RegistrationForm';


const userExists = async (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const userData = await fetch(`api/users/GetUserByUserName/${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!userData.ok) {
        if (userData.status === 404) {
          resolve(false);
        } else {
          throw new Error(`Error getting user: ${userData.status}`);
        }
      } else {
        const user = await userData.json();
        //console.log("user data: ", user);

        if (user) {
          // User exists
          resolve(true);
        } else {
          // User doesn't exist
          resolve(false);
        }
      }
    } catch (error) {
      console.error("Error in userExists:", error);
      reject(error);
    }
  });
};



const SignIn = () => {


  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [needsRegistration, setNeedsRegistration] = useState('')
  const [firebaseUser, setFirebaseUser] = useState('');

  //const newUserMutation = useMutation(submitNewUser);


  const { currentUser } = useContext(AuthContext);

  const isLoggedIn = () => {
    console.log("current user", currentUser);
    return currentUser !== null;
  };

  useEffect(() => {
    if (isLoggedIn()) {
      console.log("user is somehow logged in!")
    }
  }, [currentUser]);

  const handleSignInEmailPassword = async (e) => {
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  };

  const handleSignInWithGoogle = async () => {

    // console.log("entering HandleSignupWithGoogle");
    try {
      const auth = getAuth();
      const result = await signInWithPopup(auth, googleProvider);
      // console.log("getting HandleSignupWithGoogle results");
      // console.log("result object:", result); // Log the result object here

      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      const user = result.user;
      setFirebaseUser(user);

      const doesUserExistLocally = await userExists(user.email);
      //console.log("if user exists", doesUserExist);

      if (!doesUserExistLocally) {//in local db
        setNeedsRegistration(true)
      }
      else{
        navigate('/dashboard/');
      }
      // if (!doesUserExist) {
      //   // console.log("user doesn't exist");
      //   // console.log(user.uid);

      //   newUserMutation.mutate({ userName: user.email, authProviderId: user.uid });
      // }
      // navigate('/dashboard/');


    } catch (error) {
      console.error("Error in handleSignUpWithGoogle:", error);
    }
  };


  return (
    <div>
      {needsRegistration ? (<RegistrationForm fireBaseUser={firebaseUser}></RegistrationForm>)
        : (
          <div>
            <h2>Sign In with Google</h2>
            <button onClick={handleSignInWithGoogle}>Sign In with Google</button>
          </div>) }
    </div>
  );
};


export default SignIn;
