import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import ManageUsers from '../../logic/ManageUsers';

const useUserLocalId = () => {
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [isUserError, setIsUserError] = useState(null);
  const [userId, setUserId] = useState(null);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (!currentUser || !currentUser.uid) {
      return;
    }

    setIsUserLoading(true);

    const manageUser = new ManageUsers();
    manageUser
      .getUserIdFromAuthId(currentUser.uid)
      .then((data) => {
        if (data) {
          setUserId(data);
        }
        setIsUserLoading(false);
      })
      .catch((error) => {
        setIsUserError(error);
        setIsUserLoading(false);
      });
  }, [currentUser]);

  return { isUserLoading, isUserError, userId };
};

export default useUserLocalId;
