import { useState, useEffect, React } from 'react';
import './Sidebar.css'; // Create this file to hold any custom CSS
import { Link } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';
import { useContext } from 'react'
import { getAuth, signOut, signInWithPopup, signInWithEmailAndPassword, GoogleAuthProvider } from "firebase/auth";


const Sidebar = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { currentUser } = useContext(AuthContext);
    console.log("current user", currentUser);
  
    const auth = getAuth();
  
    const signOutLocal = () => {
      signOut(auth).then(() => {
        // Sign-out successful.
      }).catch((error) => {
        // An error happened.
      });
    }

    useEffect(() => {
        if (currentUser) {
            setIsLoading(false);
        }
    }, [currentUser]);

    return (
        <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-dark">
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                    <Link to="/" className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                        <span className="fs-5 d-none d-sm-inline">FlexNursePortal</span>
                    </Link>
                    <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                        <li className="nav-item">
                            <Link to="/dashboard/my-appointments" className="nav-link align-middle px-0">
                                <i className="fs-4 bi-house"></i> <span className="ms-1 d-none d-sm-inline">My Appointments</span>
                            </Link>
                        </li>
                        <li>
                            <a href="#submenu1" data-bs-toggle="collapse" className="nav-link px-0 align-middle collapsed" aria-expanded="false">
                                <i className="fs-4 bi-speedometer2"></i> <span className="ms-1 d-none d-sm-inline">Dashboard</span> </a>
                            <ul className="nav flex-column ms-1 collapse" id="submenu1" data-bs-parent="#menu">
                                <li className="w-100">
                                    <Link  to="/dashboard/appointments-admin" className="nav-link px-0"> <span className="d-none d-sm-inline">Schedule Appointments</span></Link>
                                </li>
                                <li>
                                    <Link  to="/dashboard/facilities-admin" className="nav-link px-0"> <span className="d-none d-sm-inline">Manage Facilities</span></Link>
                                </li>
                                <li>
                                    <Link  to="/dashboard/nurses-admin" className="nav-link px-0"> <span className="d-none d-sm-inline">Manage Nurses</span></Link>
                                </li>
                                <li>
                                    <Link  to="/dashboard/patients-admin" className="nav-link px-0"> <span className="d-none d-sm-inline">Manage Patients</span></Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="#" className="nav-link px-0 align-middle">
                                <i className="fs-4 bi-table"></i> <span className="ms-1 d-none d-sm-inline">Incoming Orders</span></a>
                        </li>
                        {/* <li>
                            <a href="#submenu2" data-bs-toggle="collapse" className="nav-link px-0 align-middle ">
                                <i className="fs-4 bi-bootstrap"></i> <span className="ms-1 d-none d-sm-inline">Bootstrap</span></a>
                            <ul className="collapse nav flex-column ms-1" id="submenu2" data-bs-parent="#menu">
                                <li className="w-100">
                                    <a href="#" className="nav-link px-0"> <span className="d-none d-sm-inline">Item</span> 1</a>
                                </li>
                                <li>
                                    <a href="#" className="nav-link px-0"> <span className="d-none d-sm-inline">Item</span> 2</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="#submenu3" data-bs-toggle="collapse" className="nav-link px-0 align-middle">
                                <i className="fs-4 bi-grid"></i> <span className="ms-1 d-none d-sm-inline">Products</span> </a>
                            <ul className="collapse nav flex-column ms-1" id="submenu3" data-bs-parent="#menu">
                                <li className="w-100">
                                    <a href="#" className="nav-link px-0"> <span className="d-none d-sm-inline">Product</span> 1</a>
                                </li>
                                <li>
                                    <a href="#" className="nav-link px-0"> <span className="d-none d-sm-inline">Product</span> 2</a>
                                </li>
                                <li>
                                    <a href="#" className="nav-link px-0"> <span className="d-none d-sm-inline">Product</span> 3</a>
                                </li>
                                <li>
                                    <a href="#" className="nav-link px-0"> <span className="d-none d-sm-inline">Product</span> 4</a>
                                </li>
                            </ul>
                        </li> */}
                        <li>
                            <a href="#" className="nav-link px-0 align-middle">
                                <i className="fs-4 bi-people"></i> <span className="ms-1 d-none d-sm-inline">Customers</span> </a>
                        </li>
                    </ul>
                    <hr />
                    <div className="dropdown pb-4">
                        <a href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src={currentUser.photoURL} alt="user_photo" width="30" height="30" className="rounded-circle" />
                            <span className="d-none d-sm-inline mx-1">{currentUser.displayName}</span>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
                            <li><a className="dropdown-item" href="#">New project...</a></li>
                            <li><a className="dropdown-item" href="#">Settings</a></li>
                            <li><Link className="dropdown-item" to="/dashboard/profile-view">Profile</Link></li>
                            <li>
                                <hr className="dropdown-divider" />
                            </li>
                            <li><Link className="dropdown-item" onClick={signOutLocal} to="/signin">Sign out</Link></li>
                        </ul>
                    </div>
                </div>)};
        </div>
    );
};

export default Sidebar;