import React, { useEffect } from 'react';
import useAppointmentsData from '../hooks/useAppointmentsData';
import useUserLocalId from '../hooks/useUserLocalId';
import useAssignAppointment from '../hooks/useAssignAppointment';

function AppointmentsList({ refetchAppointments }) {
  const { isLoading, error, data, refetch } = useAppointmentsData();
  const { userId } = useUserLocalId();
  const assignMutation = useAssignAppointment(userId);



  useEffect(() => {
    if (refetchAppointments) {
      refetch();
    }
  }, [refetchAppointments, refetch]);

  console.log(data);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleAssignToUser = (appointmentId) => {
    console.log('userId:', userId);
    console.log('appointmentId:', appointmentId);
    assignMutation.mutate(appointmentId, {
      onSuccess: () => {
        refetch();
      },
    });
  };

  return (
    <>
      <div className="container">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Location</th>
              <th scope="col">Department</th>
              <th scope="col">Room</th>
              <th scope="col">Time</th>
              <th scope="col">Nurse</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id}>
                <td>{item.locationName}</td>
                <td>{item.department ?? '-'}</td>
                <td>{item.room ?? '-'}</td>
                <td>
                  {item.startTime} - {item.endTime}
                </td>
                <td>{item.nursesId ?? <strong>Not Assigned</strong>}</td>
                <td>
                  {item.nursesId === null && (
                    <button
                      className="btn btn-primary"
                      onClick={() => handleAssignToUser(item.id)}
                    >
                      Assign to me
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default AppointmentsList;