import React, { Component } from 'react';

import PatientsEntry from './management/PatientsEntry';
import PatientsList from './management/PatientsList';

export class PatientsAdmin extends Component {
  static displayName = PatientsAdmin.name;

  render() {
    return (
      <div>
        <h2>Add Patients</h2>
        <PatientsEntry></PatientsEntry>
        <h2>List of Patients</h2>
        <PatientsList></PatientsList>
      </div>
    );
  }
}
