import React, { useState } from 'react';
import AppointmentsEntry from './management/AppointmentsEntry';
import AppointmentsList from './management/AppointmentsList';

const AppointmentsAdmin = () => {
  const [refetchAppointments, setRefetchAppointments] = useState(false);

  return (
    <div>
      <h2>Add Appointments</h2>
      <AppointmentsEntry onAppointmentAdded={() => setRefetchAppointments(true)} />
      <h2>List of Appointments</h2>
      <AppointmentsList refetchAppointments={refetchAppointments} />
    </div>
  );
};

export default AppointmentsAdmin;