import React, { Component } from 'react';

const Contact = () => {
    return (
        <section id="contact">
            <h1 className="text-center">Contact</h1>
            <div className="container mt-4 pt-4">
            <a href="mailto:rpmiller4@gmail.com?subject=FlexNursePortal">Email Us for questions.</a>
                <div className="row mt-4">
                    <div className="col-lg-16">
                        <div className="row mt-3">
                            
                            <div className="col-md-6">

                            </div>
                            <div className="col-md-6">
                            </div>
                        </div>
                        <div className="row mt-3">
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;