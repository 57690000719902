import { FetchData } from "./components/FetchData";
import Home from "./components/Home";
import { PatientsAdmin } from "./components/PatientsAdmin";
import { NursesAdmin } from "./components/NursesAdmin";
import { FacilitiesAdmin } from "./components/FacilitiesAdmin";
import AppointmentsAdmin from "./components/AppointmentsAdmin";
import SignUp from "./components/SignUp";
import SignIn from "./components/SignIn";
import Dashboard from "./components/ui/Dashboard";
import LandingPage from "./components/LandingPage";
import MyAppointments from "./components/management/MyAppointments";
import ProfileView from "./components/management/ProfileView";

export const AppRoutes = [

  {
    path: '/',
    element: <LandingPage />,
  },
  {
    path: '/dashboard/*',
    element: <Dashboard />,
    children: [
      {
        path: 'appointments-admin',
        element: <AppointmentsAdmin />,
      },
      {
        path: 'my-appointments',
        element: <MyAppointments />,
      },
      {
        path: 'patients-admin',
        element: <PatientsAdmin />,
      },
      {
        path: 'nurses-admin',
        element: <NursesAdmin />,
      },
      {
        path: 'facilities-admin',
        element: <FacilitiesAdmin />,
      },
      {
        path: 'fetch-data',
        element: <FetchData />,
      },
      {
        path: 'profile-view',
        element: <ProfileView />,
      },
    ],
  },
  {
    path: '/signup',
    element: <SignUp />,
  },
  {
    path: '/signin',
    element: <SignIn />,
  },
];

export default AppRoutes;
