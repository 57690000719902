import React, { useState, useEffect  } from "react";
import { useQuery, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom'


const RegistrationForm = (props) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [role, setRole] = useState("");
    const navigate = useNavigate();

    const submitNewUser = async (userData, endpoint) => {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        });

        if (!response.ok) {
            throw new Error('Error posting user data');
        }

        return response.json();
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("First Name:", firstName);
        console.log("Last Name:", lastName);
        console.log("Role:", role);
        console.log("userName:", props.fireBaseUser.email);
        console.log("authProviderId:", props.fireBaseUser.uid);

        submitNewUser(
            {
                firstName: firstName,
                lastName: lastName,
                role: role,
                userName: props.fireBaseUser.email,
                authProviderId: props.fireBaseUser.uid,
            },
            "/api/registration"
        );

       
        navigate('/dashboard/');
        
    };

    const handleMedicalProviderClick = () => {
        alert('Medical Provider registration is currently unavailable.');
    };

    return (
        <div className="container">
            <h2>User Role Form</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="firstName" className="form-label">
                        First Name
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="lastName" className="form-label">
                        Last Name
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Role</label>
                    <div className="d-grid gap-2">
                        <button
                            type="button"
                            className={`btn btn-${role === "nurse" ? "primary" : "outline-primary"}`}
                            onClick={() => setRole("nurse")}
                        >
                            Nurse
                        </button>
                        <button
                            type="button"
                            className={`btn btn-${role === "medicalProvider" ? "primary" : "outline-primary"}`}
                            onClick={handleMedicalProviderClick}
                        >
                            Medical Provider
                        </button>
                    </div>
                </div>
                <button type="submit" className="btn btn-primary">
                    Submit
                </button>
            </form>
        </div>
    );
};

export default RegistrationForm;
