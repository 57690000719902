import React, { Component } from 'react';

import FacilitiesEntry from './management/FacilitiesEntry';
import FacilitiesList from './management/FacilitiesList';

export class FacilitiesAdmin extends Component {
  static displayName = FacilitiesAdmin.name;

  render() {
    return (
      <div>
        <h2>Add Facilities</h2>
        <FacilitiesEntry></FacilitiesEntry>
        <h2>List of Facilities</h2>
        <FacilitiesList></FacilitiesList>
      </div>
    );
  }
}
