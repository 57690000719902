import React from 'react';

import { Landing } from './landing/Landing';

const LandingPage = () => {
  return (
    <div>
      <Landing/>
    </div>
  );
};

export default LandingPage;
